import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Victoria Heritage Apartments</h1>
            </header>
            <div className="content">
                <p>
                  3 LARGE HERITAGE MANSIONS FOR RENT IN VICTORIA BRITISH COLUMBIA. 
                  CONVERTED INTO SUITES, WITH 24 UNITS IN TOTAL.
                </p>
            </div>
        </div>
    </section>
)

export default BannerLanding
